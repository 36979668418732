.supplier-container {
  display: flex;
  justify-content: center;
  color: #3a4570;

  ul.no-bullets {
    list-style-type: none;
  }

  h1 {
    text-align: center;
  }

  .sub-header {
    font-size: 18px;
    text-align: center;
  }

  .margin-left-20 {
    margin-left: 20px;
  }

  .supplier {
    border: 1px solid #c4c8d8;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 #e3e5ec;
  }
}
