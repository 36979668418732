$buttonBackgroundColor: #ed8b00;

.filters {
  h5 {
    margin-bottom: 0;
    font-size: 0.75rem;
  }

  .ais-ClearRefinements-button,
  .ais-RefinementList-showMore {
    background-color: $buttonBackgroundColor;
    font-weight: 500;
    margin-bottom: 10px;

    &:focus {
      background-color: $buttonBackgroundColor;
    }

    &:hover {
      background-color: #c64600;
    }
  }

  .ais-RefinementList-item {
    white-space: nowrap;
  }

  .ais-RefinementList-labelText {
    font-size: 0.8rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    width: 190px;
    vertical-align: middle;
  }

  .ais-RefinementList-count {
    display: none;
  }

  .ais-Highlight-highlighted {
    font-size: 0.75rem;
  }
  .ais-Highlight-nonHighlighted {
    font-size: 0.75rem;
  }

  .ais-RefinementList-noResults {
    font-size: 0.85rem;
  }

  .ais-SearchBox-input,
  .ais-HitsPerPage-select {
    font-size: 0.8rem;
  }
}
