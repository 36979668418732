body,
h1 {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica,
    Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
}

dd,
h3 {
  overflow: hidden;
  text-overflow: ellipsis; }

[class^='ais-'] {
  font-size: inherit; }

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem; }

.text-muted {
  color: lightgray; }

.header {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #8B1F41, #E87722);
  color: #fff;
  margin-bottom: 1rem; }
  .header p {
    margin-left: auto; }

.header a {
  color: #fff;
  text-decoration: none; }

.header-title {
  font-size: 1.2rem;
  font-weight: normal; }

.supplier-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  color: #3a4570; }
  .supplier-container ul.no-bullets {
    list-style-type: none; }
  .supplier-container h1 {
    text-align: center; }
  .supplier-container .sub-header {
    font-size: 18px;
    text-align: center; }
  .supplier-container .margin-left-20 {
    margin-left: 20px; }
  .supplier-container .supplier {
    border: 1px solid #c4c8d8;
    border-radius: 0;
    box-shadow: 0 2px 5px 0 #e3e5ec; }

.contact-information address {
  font-style: normal; }

.filters h5 {
  margin-bottom: 0;
  font-size: 0.75rem; }

.filters .ais-ClearRefinements-button,
.filters .ais-RefinementList-showMore {
  background-color: #ed8b00;
  font-weight: 500;
  margin-bottom: 10px; }
  .filters .ais-ClearRefinements-button:focus,
  .filters .ais-RefinementList-showMore:focus {
    background-color: #ed8b00; }
  .filters .ais-ClearRefinements-button:hover,
  .filters .ais-RefinementList-showMore:hover {
    background-color: #c64600; }

.filters .ais-RefinementList-item {
  white-space: nowrap; }

.filters .ais-RefinementList-labelText {
  font-size: 0.8rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: inline-block;
  width: 190px;
  vertical-align: middle; }

.filters .ais-RefinementList-count {
  display: none; }

.filters .ais-Highlight-highlighted {
  font-size: 0.75rem; }

.filters .ais-Highlight-nonHighlighted {
  font-size: 0.75rem; }

.filters .ais-RefinementList-noResults {
  font-size: 0.85rem; }

.filters .ais-SearchBox-input,
.filters .ais-HitsPerPage-select {
  font-size: 0.8rem; }

em {
  background: cyan;
  font-style: normal; }

footer {
  margin-top: 25px; }

.search-panel {
  display: -webkit-flex;
  display: flex; }

.search-panel__filters {
  -webkit-flex: 1 1;
          flex: 1 1; }

.search-panel__results {
  -webkit-flex: 3 1;
          flex: 3 1; }

.searchbox {
  margin-bottom: 2rem; }

.pagination {
  margin: 2rem auto;
  text-align: center; }

.grid-container {
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 20px; }

.hits-per-page-container {
  margin-bottom: 1em; }

.ais-HitsPerPage-select {
  font-size: 0.8rem; }

.ais-Hits-item {
  width: calc(33% - 1rem);
  padding: 1.1rem; }

.hit h3 {
  font-size: 1.25rem;
  margin-top: 0;
  text-align: center;
  -webkit-margin-after: 1em;
          margin-block-end: 1em; }
  .hit h3 span {
    font-size: inherit; }

.hit h4 {
  font-size: 1.25rem; }

.hit h5 {
  text-decoration: underline;
  font-size: 1rem;
  margin-bottom: 0.25rem; }

.hit a {
  text-decoration: none; }

.hit p {
  margin: 0;
  font-size: 1rem; }
  .hit p span {
    font-size: inherit; }

.hit .margin-left-5 {
  margin-left: 5px; }

.hit .margin-top-15 {
  margin-top: 15px; }

.hit .text-center {
  text-align: center; }

