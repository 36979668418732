em {
  background: cyan;
  font-style: normal;
}

footer {
  margin-top: 25px;
}

.search-panel {
  display: flex;
}

.search-panel__filters {
  flex: 1;
}

.search-panel__results {
  flex: 3;
}

.searchbox {
  margin-bottom: 2rem;
}

.pagination {
  margin: 2rem auto;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: 250px auto;
  grid-gap: 20px;
}

.hits-per-page-container {
  margin-bottom: 1em;
}

.ais-HitsPerPage-select {
  font-size: 0.8rem;
}

// Hit

.ais-Hits-item {
  width: calc(33% - 1rem);
  padding: 1.1rem;
}

.hit {
  h3 {
    font-size: 1.25rem;
    margin-top: 0;
    text-align: center;
    margin-block-end: 1em;

    span {
      font-size: inherit;
    }
  }

  h4 {
    font-size: 1.25rem;
  }

  h5 {
    text-decoration: underline;
    font-size: 1rem;
    margin-bottom: 0.25rem;
  }

  a {
    text-decoration: none;
  }

  p {
    margin: 0;
    font-size: 1rem;

    span {
      font-size: inherit;
    }
  }

  .margin-left-5 {
    margin-left: 5px;
  }

  .margin-top-15 {
    margin-top: 15px;
  }

  .text-center {
    text-align: center;
  }
}
