@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
}

dd,
h3 {
  @include truncate;
}

[class^='ais-'] {
  font-size: inherit;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.text-muted {
  color: lightgray;
}
