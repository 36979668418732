.header {
  display: flex;
  align-items: center;
  min-height: 50px;
  padding: 0.5rem 1rem;
  background-image: linear-gradient(to right, #8B1F41, #E87722);
  color: #fff;
  margin-bottom: 1rem;

  p {
    margin-left: auto;
  }
}

.header a {
  color: #fff;
  text-decoration: none;
}

.header-title {
  font-size: 1.2rem;
  font-weight: normal;
}
